<!--
 * @Description: 
 * @FilePath: /annianjian-official-website/src/components/Footer.vue
 * @Date: 2022-09-27 10:33:23
 * @Author: Agan
 * @LastEditTime: 2022-09-27 15:26:34
-->
<template>
  <div>
    <div class="footer w flex-b">
      <dl class="contact">
        <dt>联系我们</dt>
        <dd>公司地址：广东省广州市天河棠下涌西路69号天辉商业大厦7层</dd>
        <dd>全国热线：400-009-3939</dd>
        <dd>公司邮箱：service@andaijia.cn</dd>
      </dl>
      <div class="official">
        <img src="../assets/official.png" alt="" />
        <img src="../assets/official1.png" alt="" />
      </div>
    </div>
    <div class="copyright">
      ©2022 广州百团科技有限公司 版权所有
      <a href=" https://beian.miit.gov.cn">备案号:粤ICP备2021005760号</a>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footer {
  padding: 50px;
  .contact {
    width: 640px;
    dt {
      font-size: 15px;
      border-bottom: 1px solid #000;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    dd {
      font-size: 10px;
      margin-bottom: 8px;
    }
  }
  .official {
    img {
      width: 120px;
      height: 140px;
      margin-left: 20px;
    }
  }
}
.copyright {
  height: 40px;
  background: #000;
  line-height: 40px;
  text-align: center;
  color: #fff;
  a {
    color: #fff;
  }
}
</style>
