<template>
  <div>
        <div class="banner">
            <img src="../assets/banner.jpg" alt="/banner">
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.banner{
    img{
        width: 100%;
    }
}
@media (max-width: 1200px){
    .banner{
        width: 1200px;
        margin: 0 auto;
    }
}
</style>