<template>
  <div>
        <div class="header w flex-b">  
            <h1 class="logo">
                <!-- 广州百团科技有限公司 -->
                <img src="../assets/LOGO.png" alt="安年检">
            </h1>
            
            <ul class="nav flex-b">
                <li :class="{active: index === currentIndex}" v-for="(item,index) of navs" :key="index" class="indexLi" @click="handleNav(index,item.path)">{{item.text}}</li>
            </ul>
        </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            navs: [
                {
                    text: '首页',
                    path: '/index'
                },
                {
                    text: '关于我们',
                    path: '/about'
                }
            ],
            currentIndex: 0
        }
    },
    methods: {
        handleNav(index,path){
            console.log(index,path)
            this.$router.replace(path)
            this.currentIndex = index
        }
    }
}
</script>

<style lang="scss" scoped>
.header{
    height: 100px;
        .logo{
            width: 400px;
            line-height: 100px;
            height: 100%;
            font-size: 30px;
            padding-left: 60px;
            img{
                vertical-align: middle;
            }
        } 
    .nav{
        li{
            cursor: pointer;
            height: 100px;
            line-height: 100px;
            padding: 0 26px;
            
        }
        li:hover{
            background: #036eb8;
            color: #fff;
        }
        .active{
            background: #036eb8;
            color: #fff;
        }
    }
}
</style>