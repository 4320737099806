import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import './assets/base.css'
import Index from './pages/index.vue'
// import About from './pages/about.vue'
const About = () => import('./pages/about.vue')

Vue.config.productionTip = false

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const routes = [
  { path: '/index', component: Index },
  { path: '/about', component: About },
  { path: '*', redirect: '/index'}
]

const router = new VueRouter({
  routes
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
